const PrivacyPolicyEN = () => {
  return (
    <div style={{ fontSize: 16, flex: 5 }}>
      <p>
        In order to perform and provide the Services to Customer, We need to
        collect and use the information and data of Customer. This privacy
        policy (“Privacy Policy” or “Policy”) shall help Customer to understand
        how We collect, use and/or process Customers’ personal data provided to
        us.
      </p>
      <p>
        By using our Services or registering / logging into an account on our
        platform, Customer is deemed to consent to this Policy, whereby Customer
        agrees to allow us to collect and use Customers’ information. Therefore,
        We encourage Customer to read carefully all the contents described in
        this Privacy Policy before deciding to use the Service and provide
        personal information and data to us.
      </p>
      <p>
        We reserve the right to change or adjust the content of this Privacy
        Policy at any time. The changes or new Privacy Policy will be posted
        publicly on our platform.
      </p>
      <strong>
        1. THE METHODS OF COLLECTION OF CUSTOMERS’ PERSONAL INFORMATION
      </strong>
      <p>
        “Personal information” means any information used to identify, contact
        or verify an individual.
      </p>
      <p>
        The personal information that We shall collect from
        Customer&nbsp;includes but is not limited to: name, date of birth,
        gender, address, telephone, email address, payment information (bank
        account, bank name), the company (workplace), social account and any
        personal information provided voluntarily to us by Customer.
      </p>
      <p>
        We may collect the personal information from Customer by the following
        methods:
      </p>
      <ul>
        <li>
          When Customer&nbsp;registers or logs in an account on our platform to
          use the Service;
        </li>
      </ul>
      <ul>
        <li>
          When Customer interacts with us, such as via telephone calls,
          messages, letters, emails, face-to-face meetings or through the
          conversation apps on our platform;
        </li>
      </ul>
      <ul>
        <li>
          When Customer conducts the transactions or requests the Service
          through our platform;
        </li>
      </ul>
      <ul>
        <li>
          When Customer provides or sends the feedback or complaint to us;
        </li>
      </ul>
      <ul>
        <li>
          When Customer provides the information to perform the payment through
          our payment gateways;&nbsp;
        </li>
      </ul>
      <ul>
        <li>
          When Customer submits the personal information to us for any reason.
        </li>
      </ul>
      <p>
        <strong>2. COOKIE</strong>
      </p>
      <p>
        Cookie is a small piece of data sent from our platform&nbsp;and stored
        on Customers’ computer or mobile devices by Customers’&nbsp;
        <a href="https://en.wikipedia.org/wiki/Web_browser">web browser</a>
        &nbsp;while Customer is browsing. Cookies were designed to
        remember&nbsp;the state&nbsp;information (such as items added in the
        shopping cart in an online store) or to record Customers’ browsing
        activity while Customer accesses our platform. Cookie can also be used
        to remember pieces of information that Customer previously entered into
        or fill in on our platform.
      </p>
      <p>
        Customer may refuse the use of cookies by&nbsp;selecting the appropriate
        settings on Customers’ browser. However, please be aware that if
        Customer declines / disables the cookies, it may limit or interfere with
        some functions of our platform, affecting Customers’ abilities to use
        the Services.
      </p>
      <p>
        <strong>
          3. THE&nbsp; PURPOSES TO USE CUSTOMERS’ PERSON INFORMATION
        </strong>
      </p>
      <p>
        We may collect, use, disclose and/ or process Customers’ person
        information for the following purposes:
      </p>
      <p>a. To consider and/or process Customers’ application with us;</p>
      <p>b. To identify and/or verify;</p>
      <p>c. To perform the Service for Customer;</p>
      <p>
        d. To manage and operate Customers’ use of or access to our platform;
      </p>
      <p>
        e. To process or complete Customers’ transactions/ requests for our
        products/ services;
      </p>
      <p>f. To enforce our Terms of Service;</p>
      <p>
        g. To deal with or facilitate to carry out the customer services,
        Customers’ requests, to deal with or respond to any enquiries or
        complaints given by Customer;
      </p>
      <p>
        h. To contact Customer for the purposes of managing relationship between
        us and Customer. Customer confirms and agrees that such communication by
        us could disclose the certain personal information about Customer (e.g:
        writing Customers’ personal information on packages/ envelopes to
        delivery to Customer by third parties);
      </p>
      <p>
        i. To conduct research, analysis and development activities (including,
        but not limited to: data analytics, surveys, product and service
        development and/or profiling) to improve our services or products and/or
        to enhance Customers’ experience;
      </p>
      <p>
        j. To market or promote our products or services that have been or will
        exist. Customer can unsubscribe to receive the marketing information at
        any time by using the unsubscribe function in our electronic marketing
        materials;
      </p>
      <p>
        k. To respond to legal processes or to comply with or as required by any
        applicable law, governmental or regulatory requirements of any relevant
        jurisdiction;
      </p>
      <p>
        l. To produce statistics and research for internal and statutory
        reporting and/or record-keeping requirements;
      </p>
      <p>
        m. To carry out due diligence or other screening activities (including,
        without limitation, background checks) in accordance with legal
        obligations or our risk management procedures;
      </p>
      <p>
        n. To&nbsp;prevent or investigate any fraud, unlawful activity, omission
        or misconduct, whether or not it has occurred, whether relating to
        Customers’ purchase of our product or use of our service;
      </p>
      <p>o. To store, host, back up Customers’ personal information;</p>
      <p>p. For any other purposes which We notify Customer.</p>
      <p>
        <strong>4. SHARING THE PERSONAL INFORMATION OF CUSTOMER</strong>
      </p>
      <p>
        To perform the purposes as specified in Section 3 above, We can provide
        Customers’ personal information to third parties. These third parties
        including, without limitation:
      </p>
      <ul>
        <li>Our subsidiaries, affiliates and/ or related corporations;</li>
      </ul>
      <ul>
        <li>
          Contractors, agents, service providers and other third parties We use
          to support our business;
        </li>
      </ul>
      <ul>
        <li>
          Other related parties in the event of a merger, divestiture,
          restructuring, reorganization, dissolution or other sale or transfer
          of some or all of our assets, in which the personal information is
          among the transferred assets;
        </li>
      </ul>
      <ul>
        <li>
          The third parties to whom disclosure by us is for one or more of the
          purposes specified in Section 3 above and such third parties would in
          turn be collecting and processing Customers’ personal information for
          one or more of the purposes;
        </li>
      </ul>
      <ul>
        <li>
          Other parties approved or instructed by Customer. Accordingly, We can
          share Customers’ personal information to third parties if Customer
          approves or requests.
        </li>
      </ul>
      <p>
        By our reasonable efforts, We will ensure that the third parties shared
        Customers’ personal information must take measures to protect and secure
        Customers’ personal information, to prevent the unauthorized access,
        misuse or use of Customers’ personal information; however, no measures
        can guarantee the absolute security.
      </p>
      <p>
        <strong>5. DISCLAIMER</strong>
      </p>
      <p>
        WE DO NOT GUARANTEE ABSOLUTELY THE SECURITY OF CUSTOMERS’ PERSONAL
        INFORMTION AND/OR OTHER INFORMATION THAT CUSTOMER PROVIDES ON OUR
        PLATFORMS OR THE WEBSITE OF THIRD PARTY. However, We will make&nbsp;
        efforts to &nbsp; implement a variety of security measures to maintain
        the safety and security of Customers’ personal information. Customers’
        personal information will be contained by our secured networks and will
        be only accessed by a limited number of persons or third parties who
        have been gained to access to our systems, and these persons or third
        parties are required to keep Customers’ personal information
        confidential following to our privacy policies or regulations.
      </p>
      <p>
        In an attempt to provide Customer with increased value, our platforms
        may be linked to various third party websites or platforms. These linked
        sites have separate and independent privacy policies as well as security
        arrangements, we have no control over these linked sites, including,
        without limitation the privacy policy and data collection. Therefore, We
        have no responsibility for any information, methods, security measures
        and activities of these linked sites/ platforms, Customer shall take
        responsibility for itself when accessing to the linked sites/ platforms.
        However, we seek to protect our platforms and check the integrity of the
        sites/ platforms linked to our platforms.
      </p>
      <p>
        <strong>6.</strong> <strong>THE EFFECTIVE DATE</strong>
      </p>
      <p>
        This Privacy Policy shall come into effect from&nbsp;…/…/2020. We shall
        have right to change, adjust or add the content of this Privacy Policy
        at any time. The changes or adjustments will be updated and published on
        our platform, and shall be take into effect from the time that the
        changes or adjustments have been published on our platforms.
      </p>
    </div>
  )
}

export default PrivacyPolicyEN
